import React, { Fragment, memo } from 'react'
import { FooterLeft, FooterRight, FooterWrapper } from './style'

import { footerLinks, footerImages } from '@/common/local-data'

export default memo(function JMAppFooter() {
  // 底部左侧
  const showCopys = item => {
    return (
      <Fragment key={item.title}>
        <a href={item.link}>{item.title}</a>
        <span className="line">|</span>
      </Fragment>
    )
  }
  // 底部右侧
  const showUnits = item => {
    return (
      <li key={item.link} className="item">
        <a href={item.link} rel="noopener noreferrer" target="_blank" className="link"> </a>
        <span className="title">{item.title}</span>
      </li>
    )
  }
  return (
    <FooterWrapper>
      <div className="footer-content w980">
        <FooterLeft>
          {/* <p className="copy">{footerLinks.map(item => showCopys(item))}</p> */}
          <p>
            <span className="footer-company">Blackn</span>
            <a href={"https://beian.miit.gov.cn/"}>鲁ICP备17053213号</a>
            <a href={"https://beian.miit.gov.cn/"}>
              工业和信息化部
            </a>
          </p>
         
        
        
        </FooterLeft>
        {/* <FooterRight>{footerImages.map(item => showUnits(item))}</FooterRight> */}
      </div>
    </FooterWrapper>
  )
})
