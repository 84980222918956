// 本地测试API 
// const devBaseURL = "http://localhost:2999";
// const proBaseURL = "http://localhost:2999";
// 已经部署到服务器上的API
const devBaseURL = "http://www.svip52.com:2999";
const proBaseURL = "http://www.svip52.com:2999";
export const BASE_URL =
  process.env.NODE_ENV === 'development' ? devBaseURL : proBaseURL

export const TIMEOUT = 8000
